import React from 'react'
import ProjectManagementBanner from './Assets/Images/project-management.png'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

function ProjectManagementHeader() {
  return (
    <>
      <MDBCarousel fade>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={1}
          src={ProjectManagementBanner}
          alt='...'
        >
        </MDBCarouselItem>
    </MDBCarousel>
    </>
  )
}

export default ProjectManagementHeader