import React from 'react'
import SoftwareSolutionHeader from './SoftwareSolutionHeader'
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import HumHai100 from './Assets/Images/hum-hai-100.png'

function SoftwareSolution() {
  return (
    <div>
      <SoftwareSolutionHeader/>
      <div className='container'>
        <div className='row'>
          <div className='col-12 my-2'>
            <p align='justify'><strong>
              "If you also think technology just automatically gets better every year but it actually doesn’t. It only gets better if smart people work like crazy to make it better. That’s how any technology actually gets better. And by itself technology…if people don’t work on it, it actually will decline." - Elon Musk</strong>
            </p>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-2'>
            <p className='display-6'>
              Our <span className='title-background'>Offerings</span>
            </p>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/3d-fluency/100/domain.png"
                title="Website Development"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Custom Website Development
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                We specialize in crafting bespoke websites that align perfectly with your brand identity and business goals. Our developers use cutting-edge technologies and best practices to create user-friendly, responsive, and visually captivating websites that leave a lasting impression on your visitors.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/plasticine/100/web.png"
                title="Web Application Development"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Web Application Development
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Need a powerful web application to streamline your business processes or engage your audience? Our expert developers have experience in creating feature-rich web applications tailored to your specific needs. From project management tools to social media platforms, we've got the expertise to bring your ideas to life.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/dusk/100/wordpress--v1.png"
                title="Content Management Systems (CMS)"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Content Management Systems
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Take control of your website's content with our customized CMS solutions. We empower you to update and manage your website effortlessly, even if you have no technical background. Say goodbye to dependence on developers for every small change.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/external-filled-outline-design-circle/100/external-E-commerce-seo-development-and-marketing-filled-outline-design-circle.png"
                title="E-Commerce Solutions"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  E-Commerce Solutions
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Ready to take your business online and tap into the vast potential of e-commerce? Our team can build robust, secure, and scalable e-commerce platforms that enable you to showcase your products and services effectively. We focus on optimizing the user experience to increase conversion rates and drive sales.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/dusk/100/mobile-social-networking.png"
                title="Mobile App Development"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Mobile App Development
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Extend your reach to mobile users with our mobile app development services. Whether you need an iOS, Android, or cross-platform app, we leverage the latest technologies to build high-performance, user-friendly, and visually appealing mobile applications.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/dusk/100/service.png"
                title="Website Maintenance"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Website Maintenance
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Once your website or application is up and running, we don't stop there. Our team provides ongoing maintenance and support services to ensure your platform stays secure, updated, and optimized for peak performance.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                sx={{ height: 100, width: 100, margin: 'auto' }}
                image="https://img.icons8.com/dusk/100/marketing.png"
                title="SEO & Digital Marketing"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  SEO & Digital Marketing
                </Typography>
                <Typography variant="body2" color="text.secondary"><p align='justify'>
                Having a fantastic website is not enough if your target audience can't find it. We offer SEO and digital marketing strategies to boost your online visibility, drive organic traffic, and increase your conversion rates. Let us help you climb the search engine rankings and stand out from the competition.</p>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <p align='justify'>At Amosta Solutions, we take pride in our transparent communication, on-time delivery, and a client-centric approach. Whether you're a small business, a startup, or an established enterprise, our web development services are tailored to fit your unique needs and budget.</p>
            <p align='justify'>Get in touch with us today, and let's embark on a journey to elevate your online presence and achieve digital success together!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoftwareSolution