import React from 'react'
import BackgroundCheckBanner from './Assets/Images/background-check-header.png'
import {
    MDBCarousel,
    MDBCarouselItem,
  } from 'mdb-react-ui-kit';

function BackgroundCheckHeader() {
  return (
    <div>
        <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={BackgroundCheckBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
    </div>
  )
}

export default BackgroundCheckHeader