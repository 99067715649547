import React from 'react'
import OurTeamHeaderBanner from './Assets/Images/our-team-header.png'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

function OurTeamHeader() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={OurTeamHeaderBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default OurTeamHeader