import React from "react";
import img from "../Assets/Images/Ev/header.jpg";

export const EvHeader = () => {
  return (
    <div className="evheader">
      <img src={img} alt="" style={{ width: "100%" }} />
    </div>
  );
};
