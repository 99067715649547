import React from "react";
import LEDLightsHeader from "./LEDLightsHeader";
import LEDBulb from "./Assets/Images/led-lights/led-bulb.png";
import tubelight from "./Assets/Images/led-lights/tube-light.png";
import ConcealedRoundLight6W from "./Assets/Images/led-lights/concealed-light-round-cool-white.png";
import DownLightRoundCoolWhite6W from "./Assets/Images/led-lights/down-light-round-cool-white.png";
import DownLightSquareCoolWhite6W from "./Assets/Images/led-lights/down-light-square-cool-white.png";
import DownLightRoundCoolWhite12W from "./Assets/Images/led-lights/down-light-round-cool-white-2.png";
import DownLightSquareCoolWhite12W from "./Assets/Images/led-lights/down-light-square-cool-white-2.png";
import DownLightsRoundCoolWhite18W from "./Assets/Images/led-lights/down-light-round-cool-white-3.png";
import DownLightSquareCoolWhite18W from "./Assets/Images/led-lights/down-light-square-cool-white-3.png";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";

function LEDLights() {
  return (
    <div>
      <LEDLightsHeader />
      <div className="container1">
        <div className="row my-3">
          <div className="col-12 text-center">
            <p className="display-6">
              Amosta Range of{" "}
              <span className="title-background">LED LIGHTS</span>
            </p>
          </div>
        </div>
        <div className="row my-2 mx-3">
        <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${LEDBulb})`}}> </div>
                <p className="item"> LED Bulb 9W</p>
                <p className="item">
                Item Code: LB-9W-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${tubelight})`}}> </div>
                <p className="item"> Tube Light 20W</p>
                <p className="item">
                Item Code: TL-20W-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${ConcealedRoundLight6W})`}}> </div>
                <p className="item"> Concealed Round Light 6W</p>
                <p className="item">
                Item Code: CL-6WRD-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightRoundCoolWhite6W})`}}> </div>
                <p className="item"> Down Light Round Cool White 6W</p>
                <p className="item">
                Item Code: CL-6WRD-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightSquareCoolWhite6W})`}}> </div>
                <p className="item"> Down Light Square Cool White 6W</p>
                <p className="item">
                Item Code: PL-6WSQ-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightRoundCoolWhite12W})`}}> </div>
                <p className="item"> Down Light Round Cool White 12W</p>
                <p className="item">
                Item Code: PL-12WRD-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightSquareCoolWhite12W})`}}> </div>
                <p className="item"> Down Light Square Cool White 12W</p>
                <p className="item">
                Item Code: PL-12WSQ-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightsRoundCoolWhite18W})`}}> </div>
                <p className="item">Down Lights Round Cool White 18W</p>
                <p className="item">
                Item Code: PL-18WRD-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-2">
            <div class="container1">
              <div class="wrapper">
                <div class="banner-image1" style={{backgroundImage:`url(${DownLightSquareCoolWhite18W})`}}> </div>
                <p className="item"> Down Light Square Cool White 18W</p>
                <p className="item">
                Item Code: PL-18WSQ-CW
                </p>
              </div>
              <div class="button-wrapper">
                <button class="btn outline">DETAILS</button>
                <button class="btn fill">BUY NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LEDLights;
