import React from 'react'
import './Assets/Css/style.css';
import HomeBanner from './HomeBanner';
import SoftwareDevelopmentBanner from './Assets/Images/software-development.jpg';
import VerificationBanner from './Assets/Images/verification.jpg';
import RecruitmentBanner from './Assets/Images/recruitment.jpg';
import ProjectManagementBanner from './Assets/Images/project-management.jpg';
import WhoWeAreBanner from './Assets/Images/what-we-do.png';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import ClientSlider from './ClientSlider';
function Home() {
  return (
    <div>
        <HomeBanner/>
        <div className='container-fluid my-3'>
          <div className='row'>
            <div className='col-md-12 text-center'><p className='display-6'><strong className='span-red-bold'>AMOSTA</strong> WORLD OF <strong className='span-red-bold'>MOBILITY</strong> & <strong className='span-red-bold'>CONNECTIVITY</strong></p></div>
          </div>
          <div className='row my-3 mb-3'>
            <div className='col-md-6 text-center'>
              <img src={WhoWeAreBanner} className='img-fluid web-image' alt='What We Do'/>
            </div>
            <div className='col-md-6'>
              <p align="justify">Amosta is a technology company, committed to cater the requirements of the people to live a better & happy life in era of internet of things, futuristic smart cities, connected world & super intelligent ecosystem. Be it your business, your family, your organization, your field resources, your remote assets or your workforce, everything is now a dot on big matrix of connected world. We equip businesses & organizations with our well researched solutions to exceed their productivity, efficiency, ROI and business objectives. We are a team of self-motivated tech enthusiast who thrive on building web based applications based on cutting-edge technologies.</p>
              <p align="justify">“Started in 2014 as a “Make in India” Tablet & Smart Phone manufacturing company, we have come a long way since then nurturing and developing countless business ideas and shaping a few of them into a successful business.</p>
              <a href='about'><MDBBtn color='dark'>Know More</MDBBtn></a>
            </div>
          </div>
          <div className='row my-5 mb-3'>
            <div className='col-md-12 text-center'>
              <p className='display-6'>OUR VERTICALS</p>
            </div>
          </div>
          <div className='row text-center'>
            <div className='col-md-3'>
              <MDBCard className='software-border'>
                <MDBCardImage src={SoftwareDevelopmentBanner} position='top' />
                <MDBCardBody>
                  <MDBCardTitle>Software Solutions</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-3'>
              <MDBCard className='background-border'>
                <MDBCardImage src={VerificationBanner} position='top' />
                <MDBCardBody>
                  <MDBCardTitle>Background Check Solutions</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-3'>
              <MDBCard className='recruitment-border'>
                <MDBCardImage src={RecruitmentBanner} position='top' />
                <MDBCardBody>
                  <MDBCardTitle>Recruitment Solutions</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-3'>
              <MDBCard className='project-border'>
                <MDBCardImage src={ProjectManagementBanner} position='top' />
                <MDBCardBody>
                  <MDBCardTitle>Project Management</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
          <div className='row my-5'>
            <div className='col-md-12 text-center'>
              <p className='display-6'>OUR CLIENTS</p>
            </div>
            <div className='col-12'>
              <ClientSlider/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Home