import React, { useState } from "react";
import "./Assets/Css/style.css";
import "./style.css";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon,
} from "mdb-react-ui-kit";

import Logo from "./Assets/Images/amosta-logo-small.png";

export default function Navbar() {
  const [isHover, setIsHover] = useState(false);
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);
  const [isHover7, setIsHover7] = useState(false);
  const [isHover8, setIsHover8] = useState(false);
  const [isHover9, setIsHover9] = useState(false);
  const [isHover11, setIsHover11] = useState(false);
  const [isHover12, setIsHover12] = useState(false);
  const [isHover13, setIsHover13] = useState(false);
  const [isHover14, setIsHover14] = useState(false);
  const [isHover15, setIsHover15] = useState(false);
  const [isHover16, setIsHover16] = useState(false);
  const [isHover17, setIsHover17] = useState(false);
  const [isHover18, setIsHover18] = useState(false);
  const [isHover19, setIsHover19] = useState(false);

  const [showNavRight, setShowNavRight] = useState(false);

  return (
    <>
      <MDBNavbar
        expand="lg"
        light
        bgColor="light"
        className=" sticky-top d-sm-block d-none"
      >
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <img src={Logo} alt="Logo" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarRightAlignExample"
            aria-controls="navbarRightAlignExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavRight(!showNavRight)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showNavRight}>
            <MDBNavbarNav
              right
              fullWidth={false}
              className="ms-auto ps-lg-0"
              style={{ paddingLeft: "0.15rem" }}
            >
              <MDBNavbarItem>
                <MDBNavbarLink
                  className="navb"
                  active
                  aria-current="page"
                  href="/"
                  style={{
                    backgroundColor: isHover ? "#da1b2a" : "white",
                    color: isHover ? "white" : "black",
                  }}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  {/* <AiOutlineHome/> */}
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    style={{
                      backgroundColor: isHover1 ? "#da1b2a" : "white",
                      color: isHover1 ? "white" : "black",
                    }}
                    onMouseEnter={() => setIsHover1(true)}
                    onMouseLeave={() => setIsHover1(false)}
                  >
                    {/* <FcAbout/> */}
                    About Us
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        className="navb"
                        href="about"
                        style={{
                          backgroundColor: isHover8 ? "#da1b2a" : "white",
                          color: isHover8 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover8(true)}
                        onMouseLeave={() => setIsHover8(false)}
                      >
                        Who We Are
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        className="navb"
                        href="ourteam"
                        style={{
                          backgroundColor: isHover9 ? "#da1b2a" : "white",
                          color: isHover9 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover9(true)}
                        onMouseLeave={() => setIsHover9(false)}
                      >
                        Our Team
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    style={{
                      backgroundColor: isHover2 ? "#da1b2a" : "white",
                      color: isHover2 ? "white" : "black",
                    }}
                    onMouseEnter={() => setIsHover2(true)}
                    onMouseLeave={() => setIsHover2(false)}
                  >
                    {/* <AiOutlineSolution/> */}
                    Solutions
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="backgroundcheck"
                        className="navb"
                        style={{
                          backgroundColor: isHover11 ? "#da1b2a" : "white",
                          color: isHover11 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover11(true)}
                        onMouseLeave={() => setIsHover11(false)}
                      >
                        Background Checks
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="softwaresolution"
                        className="navb"
                        style={{
                          backgroundColor: isHover12 ? "#da1b2a" : "white",
                          color: isHover12 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover12(true)}
                        onMouseLeave={() => setIsHover12(false)}
                      >
                        Software Solutions
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="projectmanagement"
                        className="navb"
                        style={{
                          backgroundColor: isHover13 ? "#da1b2a" : "white",
                          color: isHover13 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover13(true)}
                        onMouseLeave={() => setIsHover13(false)}
                      >
                        Project Management
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    style={{
                      backgroundColor: isHover3 ? "#da1b2a" : "white",
                      color: isHover3 ? "white" : "black",
                    }}
                    onMouseEnter={() => setIsHover3(true)}
                    onMouseLeave={() => setIsHover3(false)}
                  >
                    StartUp Studio
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="startupstudio"
                        className="navb"
                        style={{
                          backgroundColor: isHover14 ? "#da1b2a" : "white",
                          color: isHover14 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover14(true)}
                        onMouseLeave={() => setIsHover14(false)}
                      >
                        Introduction
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="startupstudioofferings"
                        className="navb"
                        style={{
                          backgroundColor: isHover15 ? "#da1b2a" : "white",
                          color: isHover15 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover15(true)}
                        onMouseLeave={() => setIsHover15(false)}
                      >
                        Our Offerings
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        href="startupstudioprocess"
                        className="navb"
                        style={{
                          backgroundColor: isHover16 ? "#da1b2a" : "white",
                          color: isHover16 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover16(true)}
                        onMouseLeave={() => setIsHover16(false)}
                      >
                        Our Process
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link"
                    style={{
                      backgroundColor: isHover4 ? "#da1b2a" : "white",
                      color: isHover4 ? "white" : "black",
                    }}
                    onMouseEnter={() => setIsHover4(true)}
                    onMouseLeave={() => setIsHover4(false)}
                  >
                    {/* <FaProductHunt/> */}
                    Products
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink
                        className="navb"
                        href="ledlights"
                        style={{
                          backgroundColor: isHover17 ? "#da1b2a" : "white",
                          color: isHover17 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover17(true)}
                        onMouseLeave={() => setIsHover17(false)}
                      >
                        LED Light Solutions
                      </MDBNavbarLink>
                      <MDBNavbarLink
                        className="navb"
                        href="ev"
                        style={{
                          backgroundColor: isHover19 ? "#da1b2a" : "white",
                          color: isHover19 ? "white" : "black",
                        }}
                        onMouseEnter={() => setIsHover19(true)}
                        onMouseLeave={() => setIsHover19(false)}
                      >
                        EV Vehicles
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink
                  className="navb"
                  active
                  aria-current="page"
                  href="careers"
                  style={{
                    backgroundColor: isHover5 ? "#da1b2a" : "white",
                    color: isHover5 ? "white" : "black",
                  }}
                  onMouseEnter={() => setIsHover5(true)}
                  onMouseLeave={() => setIsHover5(false)}
                >
                  {/* <MdGroup/> */}
                  Careers
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink
                  className="navb"
                  active
                  aria-current="page"
                  href="contactus"
                  style={{
                    backgroundColor: isHover7 ? "#da1b2a" : "white",
                    color: isHover7 ? "white" : "black",
                  }}
                  onMouseEnter={() => setIsHover7(true)}
                  onMouseLeave={() => setIsHover7(false)}
                >
                  Contact Us
                </MDBNavbarLink>
              </MDBNavbarItem>

              {/* <MDBNavbarItem>
                <MDBNavbarLink
                  className="navb"
                  active
                  aria-current="page"
                  href="/ev"
                  style={{
                    backgroundColor: isHover18 ? "#da1b2a" : "white",
                    color: isHover18 ? "white" : "black",
                  }}
                  onMouseEnter={() => setIsHover18(true)}
                  onMouseLeave={() => setIsHover18(false)}
                >
                  EV Vehicles
                </MDBNavbarLink>
              </MDBNavbarItem> */}

            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <MDBNavbar
        expand="lg"
        light
        bgColor="light"
        className=" d-block d-sm-none"
      >
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <img src={Logo} alt="Logo" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarRightAlignExample"
            aria-controls="navbarRightAlignExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavRight(!showNavRight)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showNavRight}>
            <MDBNavbarNav
              right
              fullWidth={false}
              className="ms-auto ps-lg-0"
              style={{ paddingLeft: "0.15rem" }}
            >
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page" href="/">
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    About Us
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="about">Who We Are</MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="ourteam">Our Team</MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="lifeatamosta">
                        Life @ Amosta
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Solutions
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="backgroundcheck">
                        Background Checks
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="softwaresolution">
                        Software Solutions
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="projectmanagement">
                        Project Management
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    StartUp Studio
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="startupstudio">
                        Introduction
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="startupstudioofferings">
                        Our Offerings
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="startupstudioprocess">
                        Our Process
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Products
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBNavbarLink href="ledlights">
                        LED Light Solutions
                      </MDBNavbarLink>
                      <MDBNavbarLink href="ev">
                      EV Vehicles
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                    {/* <MDBDropdownItem><MDBNavbarLink href='softwaresolution'>Software Solutions</MDBNavbarLink></MDBDropdownItem>
                      <MDBDropdownItem><MDBNavbarLink href='projectmanagement'>Project Management</MDBNavbarLink></MDBDropdownItem> */}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page" href="careers">
                  Careers
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page" href="contactus">
                  Contact Us
                </MDBNavbarLink>
              </MDBNavbarItem>

              {/* <MDBNavbarItem>
                <MDBNavbarLink active aria-current="page" href="/ev">
                  EV Vehicles
                </MDBNavbarLink>
              </MDBNavbarItem> */}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
