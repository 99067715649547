import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import img from "../Assets/Images/Ev/20.png";

const StyledImage = styled('img')({
  maxWidth: '100%',
});

const StyledParagraph = styled(Typography)({
  padding: '20px',
  lineHeight: '1.6',
  '& ul': {
    paddingLeft: '20px',
  },
  '& li': {
    marginBottom: '10px',
  },
});

export const EvDetails = () => {
  return (
    <div className="centered">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <StyledImage src={img} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledParagraph variant="body1">
            <strong>About e-rishsah</strong>
            <br />
            At e-rishsah, we are committed to revolutionizing the way businesses operate and grow in the digital age. Our innovative solutions empower organizations to streamline their processes, enhance efficiency, and drive sustainable growth.
            <br /><br />
            <strong>Our Mission</strong>
            <br />
            Our mission is to provide cutting-edge technology solutions that enable businesses to thrive in a rapidly evolving digital landscape. We strive to deliver value-driven products and services that exceed our clients' expectations and contribute to their success.
            <br /><br />
            <strong>Our Values</strong>
            <br />
            <ul>
              <li><strong>Innovation:</strong> We continuously innovate to stay ahead of industry trends and deliver solutions that drive business transformation.</li>
              <li><strong>Integrity:</strong> We conduct business with honesty, transparency, and respect for our clients, partners, and employees.</li>
              <li><strong>Excellence:</strong> We are committed to excellence in everything we do, from product development to customer service.</li>
              <li><strong>Collaboration:</strong> We believe in the power of collaboration and work closely with our clients to achieve mutual success.</li>
            </ul>
            <strong>What Sets Us Apart</strong>
            <br />
            <ul>
              <li><strong>Expertise:</strong> Our team consists of highly skilled professionals with expertise in the latest technologies and industry best practices.</li>
              <li><strong>Customer-Centric Approach:</strong> We prioritize our clients' needs and tailor our solutions to meet their specific requirements.</li>
              <li><strong>Innovative Solutions:</strong> We leverage cutting-edge technologies to develop innovative solutions that address our clients' most pressing challenges.</li>
            </ul>
          </StyledParagraph>
        </Grid>
      </Grid>
    </div>
  );
};
