import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

function AmostaTimeline() {
  return (
    <div>
        <Timeline
        sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
            },
        }}
        >
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2014
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Amosta was incorporated</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2015
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Set-up of smartphone and tablet assembly unit</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2015
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Amosta's own tablets and smartphones were launched</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2016
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Took over verification business process</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2017
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Started police verification, education verification and criminal check</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2018
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Started residence verification and past employment check</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2019
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Bagged its 1st on-boarding project along with its payroll management</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2019
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Started recruitment service to meet project needs</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2020
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Launched Hum Hai</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2021
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Expanded our recruitment circle to various business categories and not just On-boarding related projects</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Introduced subscription plans for job seekers and platform for employers to solve their recruitment issues</TimelineContent>
            </TimelineItem>
                <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                2023
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Launched our Start-Up Studio</TimelineContent>
            </TimelineItem>
        </Timeline>
    </div>
  )
}

export default AmostaTimeline