import React from "react";
import { EvDetails } from "./EvDeatils";
import { EvForm } from "./EvForm";
import { EvSlider } from "./EvSlider";
import { EvHeader } from "./EvHeader";

export const EVVehicles = () => {
  return (
    <div style={{ margin: "auto" }}>
      <div>
        <EvHeader />
      </div>
      <EvDetails />
      <EvSlider />
      <EvForm />
    </div>
  );
};
