import React from "react";
import "./clientslider.scss";
import CarouselSlider from "react-carousel-slider";
import AmazonPayLogo from "./Assets/Images/clients/amazon-pay.png";
import AmazonSellerLogo from "./Assets/Images/clients/amazon-seller.png";
import BharatZupposLogo from "./Assets/Images/clients/bharat-zuppos.png";
import BigBasketLogo from "./Assets/Images/clients/big-basket.png";
import CapgeminiLogo from "./Assets/Images/clients/capgemini.png";
import CaptainFreshLogo from "./Assets/Images/clients/captain-fresh.png";
import DhaniLogo from "./Assets/Images/clients/dhani.png";
import DotPeLogo from "./Assets/Images/clients/dot-pe.png";
import DPSLogo from "./Assets/Images/clients/dps.png";
import FrankfinnLogo from "./Assets/Images/clients/frankfinn.png";
import GenpactLogo from "./Assets/Images/clients/genpact.png";
import GoogleMapsLogo from "./Assets/Images/clients/google-maps.png";
import GooglePayLogo from "./Assets/Images/clients/google-pay.png";
import GumletLogo from "./Assets/Images/clients/gumlet.png";
import HCLLogo from "./Assets/Images/clients/hcl.png";
import HindustanSecuritiesLogo from "./Assets/Images/clients/hindustan-securities.png";
import HSBCLogo from "./Assets/Images/clients/hindustan-securities.png";
import InfosysLogo from "./Assets/Images/clients/infosys.png";
import JindalXLogo from "./Assets/Images/clients/jindal-x.png";
import JioMartLogo from "./Assets/Images/clients/jio-mart.png";
import JustDialLogo from "./Assets/Images/clients/justdial.png";
import KnorrBremseLogo from "./Assets/Images/clients/knorr-bremse.png";
import MeeshoLogo from "./Assets/Images/clients/meesho.png";
import MVNLogo from "./Assets/Images/clients/mvn.png";
import NimbusHarborLogo from "./Assets/Images/clients/nimbus-harbor.png";
import OkayGoLogo from "./Assets/Images/clients/okay-go.png";
import PagarBookLogo from "./Assets/Images/clients/pagar-book.png";
import PathwaysSchoolsLogo from "./Assets/Images/clients/pathways-schools.png";
import PayswiffLogo from "./Assets/Images/clients/payswiff.png";
import PineLabsLogo from "./Assets/Images/clients/pine-labs.png";
import RapidoLogo from "./Assets/Images/clients/rapido.png";
import RelianceDigitalLogo from "./Assets/Images/clients/reliance-digital.png";
import ShadowfaxLogo from "./Assets/Images/clients/shadowfax.png";
import SietzLogo from "./Assets/Images/clients/sietz.png";
import SpinnyLogo from "./Assets/Images/clients/spinny.png";
import STJLogo from "./Assets/Images/clients/stj.png";
import SwiggyLogo from "./Assets/Images/clients/swiggy.png";
import TheBritishSchoolLogo from "./Assets/Images/clients/the-british-school.png";
import TrufeduLogo from "./Assets/Images/clients/trufedu.png";
import UberLogo from "./Assets/Images/clients/uber.png";
import VacoBinarySemanticsLogo from "./Assets/Images/clients/vaco-binary-semantics.png";
import ZeptoLogo from "./Assets/Images/clients/zepto.png";
import ZomatoLogo from "./Assets/Images/clients/zomato.png";
import ZyppLogo from "./Assets/Images/clients/zypp.png";

// const delay = 2500;
// const slideWidth = 30;

let data = [
  {
    imgSrc: UberLogo,
  },
  {
    imgSrc: VacoBinarySemanticsLogo,
  },
  {
    imgSrc: ZeptoLogo,
  },
  {
    imgSrc: ZomatoLogo,
  },
  {
    imgSrc: ZyppLogo,
  },
  {
    imgSrc: NimbusHarborLogo,
  },
  {
    imgSrc: OkayGoLogo,
  },
  {
    imgSrc: PagarBookLogo,
  },
  {
    imgSrc: PathwaysSchoolsLogo,
  },
  {
    imgSrc: PayswiffLogo,
  },
  {
    imgSrc: PineLabsLogo,
  },
  {
    imgSrc: RapidoLogo,
  },
  {
    imgSrc: RelianceDigitalLogo,
  },
  {
    imgSrc: ShadowfaxLogo,
  },
  {
    imgSrc: SietzLogo,
  },
  {
    imgSrc: SpinnyLogo,
  },
  {
    imgSrc: STJLogo,
  },
  {
    imgSrc: SwiggyLogo,
  },
  {
    imgSrc: TheBritishSchoolLogo,
  },
  {
    imgSrc: TrufeduLogo,
  },
  {
    imgSrc: AmazonPayLogo,
  },
  {
    imgSrc: AmazonSellerLogo,
  },
  {
    imgSrc: BharatZupposLogo,
  },
  {
    imgSrc: BigBasketLogo,
  },
  {
    imgSrc: CapgeminiLogo,
  },
  {
    imgSrc: CaptainFreshLogo,
  },
  {
    imgSrc: DhaniLogo,
  },
  {
    imgSrc: DotPeLogo,
  },
  {
    imgSrc: DPSLogo,
  },
  {
    imgSrc: FrankfinnLogo,
  },
  {
    imgSrc: GenpactLogo,
  },
  {
    imgSrc: GoogleMapsLogo,
  },
  {
    imgSrc: GooglePayLogo,
  },
  {
    imgSrc: GumletLogo,
  },
  {
    imgSrc: HCLLogo,
  },
  {
    imgSrc: HindustanSecuritiesLogo,
  },
  {
    imgSrc: HSBCLogo,
  },
  {
    imgSrc: InfosysLogo,
  },
  {
    imgSrc: JindalXLogo,
  },
  {
    imgSrc: JioMartLogo,
  },
  {
    imgSrc: JustDialLogo,
  },
  {
    imgSrc: KnorrBremseLogo,
  },
  {
    imgSrc: MeeshoLogo,
  },
  {
    imgSrc: MVNLogo,
  },
];

let sliderBoxStyle = {
  height: "250px",
  background: "tranparent",
};

let itemsStyle = {
  background: "transparent",
};

let textBoxStyle = {
  // textAlign: "left"
  // ,width:"50%"
  // , background: "transparent"
  // , fontSize: "36px"
  // , fontWeight: 300
};

let buttonSetting = {
  placeOn: "middle-inside",
  hoverEvent: true,
  style: {
    left: {
      margin: "0px 0px 0px 10px",
    },
    right: {
      margin: "0px 10px 0px 0px",
    },
  },
};

let manner = {
  autoSliding: { interval: "4s" },
  duration: "0.1s",
};

function ClientSlider() {
  return (
    <>
      <CarouselSlider
        slideItems={data}
        manner={manner}
        buttonSetting={buttonSetting}
        sliderBoxStyle={sliderBoxStyle}
        itemsStyle={itemsStyle}
        textBoxStyle={textBoxStyle}
      />
    </>
  );
}

export default ClientSlider;
