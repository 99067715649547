import React from 'react'
import StartupStudiobanner from './Assets/Images/startup-studio-banner-3.png'
import BackgroundCheckBanner from './Assets/Images/background-check-banner.png'
import SoftwareDevBanner from './Assets/Images/software-solutions-banner.png'
import {
    MDBCarousel,
    MDBCarouselItem,
  } from 'mdb-react-ui-kit';

function HomeBanner() {
  return (
    <MDBCarousel showControls dealy={3000}>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={StartupStudiobanner}
        alt='...'
      >
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src={BackgroundCheckBanner}
        alt='...'
      >
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src={SoftwareDevBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
    
  );
}

export default HomeBanner