import React from 'react'

function RefundPolicy() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        Refund & Cancellation
                    </p>
                    <p align='justify'>
                        Our prime motto at Amosta Solutions Pvt. Ltd is to serve our customers at the highest desired levels. After your purchase, if you are not completely satisfied with any item purchased from us, we will be glad to refund your full money without any quibble.
                    </p>
                    <p align='justify'>
                        There is no risk when you are buying on Amosta Solutions Pvt. Ltd. Your complete shopping satisfaction is our No.1 Priority. We want you happy each and every time you buy from us. However, if due to some unforeseen situation you are not happy with the product then the following terms apply for a mutually consented resolution of the situation.
                    </p>
                </div>
                <div className='col-12'>
                    <p className='display-6'>Guidelines</p>
                    <p align='justify'>
                        <strong>Cancellation Policy:</strong> Amosta Solutions Pvt. Ltd believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy: <br/>
                        <ul>
                            <li>Cancellations will be considered only if the request is made within 72 hours of placing an order. However, the cancellation request will not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
                        </ul>
                    </p>
                    <p><strong>Refund Policy</strong></p>
                    <p align='justify'>
                        We take stringent measures to ensure that the items delivered to you are in perfect condition. However, there is a remote possibility that: The item may be damaged during transit. To contact Courier Service within 48 hours also inform our technical support number +91 124 4215321 with all details. We'll deliver replaced item after verification. Or there might be a manufacturing defect then the Manufactures Service Policy will apply. Or that a wrong item is delivered to you by mistake.
                    </p>
                    <p align='justify'>
                        We will do replacement of your item as early as possible with no extra shipping cost to you and will accept the wrong item picked up by you in the original packed condition. If in above cases, we are not able to replace the original product ordered by you due to unavailability then we will offer you an alternate product and if that is not acceptable to you then we can refund the money and make the earlier transaction void.
                    </p>
                    <p align='justify'>
                        <strong>Refund:</strong> Refund can happen through Amosta Solutions Pvt. Ltd. Write to us at refund@amosta.com before shipment of product, customer can avail full refund. Write to refund@amosta.com with full payment details. After shipment, Customer will be charged a 20% restocking fee if the product is received back within 15 days of original shipment. After 15 days of original shipment, no refunds will be offered, and customers can resolve issues through the normal warranty process. Cancellation charges of Rs 500 will be levied if payment is made online.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default RefundPolicy