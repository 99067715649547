import React from "react";
// import { useState,useCallback,useEffect } from "react";
import FsLightbox from "fslightbox-react";
import ModalImage from "react-modal-image";
import LEDBulb from "./Assets/Images/led-lights/led-bulb.png";
import tubelight from "./Assets/Images/led-lights/tube-light.png";
import ConcealedRoundLight6W from "./Assets/Images/led-lights/concealed-light-round-cool-white.png";
import DownLightRoundCoolWhite6W from "./Assets/Images/led-lights/down-light-round-cool-white.png";
import DownLightSquareCoolWhite6W from "./Assets/Images/led-lights/down-light-square-cool-white.png";
import DownLightRoundCoolWhite12W from "./Assets/Images/led-lights/down-light-round-cool-white-2.png";
import DownLightSquareCoolWhite12W from "./Assets/Images/led-lights/down-light-square-cool-white-2.png";
import DownLightsRoundCoolWhite18W from "./Assets/Images/led-lights/down-light-round-cool-white-3.png";
import DownLightSquareCoolWhite18W from "./Assets/Images/led-lights/down-light-square-cool-white-3.png";
import As from "./Assets/Images/background-check-banner.png"
import 'react-slideshow-image/dist/styles.css'



function LifeAtAmosta() {
 
  return (
    <div>
      
      <div className=""
      style={{ width:"75%",display: "flex", flexDirection: "row", flexWrap: "wrap" ,margin:"auto"}}>
          <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image"  small={LEDBulb}
                    large={LEDBulb}
                    alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={tubelight} large={tubelight} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={ConcealedRoundLight6W} large={ConcealedRoundLight6W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightRoundCoolWhite6W} large={DownLightRoundCoolWhite6W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightSquareCoolWhite6W} large={DownLightSquareCoolWhite6W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightRoundCoolWhite12W} large={DownLightRoundCoolWhite12W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightSquareCoolWhite12W} large={DownLightSquareCoolWhite12W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightsRoundCoolWhite18W} large={DownLightsRoundCoolWhite18W} alt="sg1" />
                </div>
                <div style={{border:"1px solid red",margin:"2px"}}>
                    <ModalImage className="image" small={DownLightSquareCoolWhite18W} large={DownLightSquareCoolWhite18W} alt="sg1" />
                </div>
       
      
      </div>
      
      {/* <FsLightbox
        toggler={toggler}
        sources={[
          LEDBulb,
          tubelight,
          ConcealedRoundLight6W,
          DownLightRoundCoolWhite6W,
          DownLightSquareCoolWhite6W,
          DownLightRoundCoolWhite12W,
          DownLightSquareCoolWhite12W,
          DownLightsRoundCoolWhite18W,
          DownLightSquareCoolWhite18W,
          As,
        ]}
      /> */}
    </div>
  );
}

export default LifeAtAmosta;
