import React from 'react'
import AboutUsHeaderBanner from './Assets/Images/about-us-header.png'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

function AboutUsHeader() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={AboutUsHeaderBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default AboutUsHeader