import React from 'react'
import StartUpStudioHeader from './StartUpStudioHeader'
import BusinessIdea from './Assets/Images/business-idea.png'
import ExpertSolution from './Assets/Images/expert-solution.png'
import IdeaBreakdown from './Assets/Images/idea-breakdown.png'
import IdeaExecution from './Assets/Images/idea-execution.png'
import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function StartUpStudioProcess() {
  return (
    <div>
      <StartUpStudioHeader/>
      <div className='container-fluid'>
        <div className='row my-2'>
          <div className='col-12 text-center'>
            <h1 className='display-6'>
              A Walkthrough Of <span className='title-background'>Our Process</span>
            </h1>
          </div>
        </div>
        <div className='row my-2'>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: 345 }}>
              {/* <CardHeader
              sx={{color: '#ffffff'}}
              color="white"
                title="Business idea narrated by a potential entrepreneur"
                className='text-center title-background'                
              /> */}
              <CardMedia
                component="img"
                height="100%"
                image={BusinessIdea}
                alt="Business Idea"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  {/* <ul>
                    <li>Support in making business strategies</li>
                    <li>In-depth market research for making a business model</li>
                    <li>Mentorship</li>
                  </ul> */}
                  <strong>Step 1:</strong> Business idea narrated by a potential entrepreneur
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: 345 }}>
              {/* <CardHeader
              sx={{color: '#ffffff'}}
              color="white"
                title="Business idea narrated by a potential entrepreneur"
                className='text-center title-background'                
              /> */}
              <CardMedia
                component="img"
                height="100%"
                image={IdeaBreakdown}
                alt="Idea Breakdown"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  {/* <ul>
                    <li>Support in making business strategies</li>
                    <li>In-depth market research for making a business model</li>
                    <li>Mentorship</li>
                  </ul> */}
                  <strong>Step 2:</strong> Idea broken into micro ideas to simplify the ultimate goal
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: 345 }}>
              {/* <CardHeader
              sx={{color: '#ffffff'}}
              color="white"
                title="Business idea narrated by a potential entrepreneur"
                className='text-center title-background'                
              /> */}
              <CardMedia
                component="img"
                height="100%"
                image={ExpertSolution}
                alt="Expert Solution"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  {/* <ul>
                    <li>Support in making business strategies</li>
                    <li>In-depth market research for making a business model</li>
                    <li>Mentorship</li>
                  </ul> */}
                  <strong>Step 3:</strong> Our team of experts analyze every aspect of your idea and provide the best possible solution
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-md-3 text-center my-2'>
            <Card sx={{ maxWidth: 345 }}>
              {/* <CardHeader
              sx={{color: '#ffffff'}}
              color="white"
                title="Business idea narrated by a potential entrepreneur"
                className='text-center title-background'                
              /> */}
              <CardMedia
                component="img"
                height="100%"
                image={IdeaExecution}
                alt="Idea Execution"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  {/* <ul>
                    <li>Support in making business strategies</li>
                    <li>In-depth market research for making a business model</li>
                    <li>Mentorship</li>
                  </ul> */}
                  <strong>Step 4:</strong> Parallel execution starts on various fields like tech development, compliance.
                </Typography>
              </CardContent>      
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartUpStudioProcess