import React, { useState } from "react";
import "./Ev.css";
import { Button, Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const EvForm = () => {
  const [f_name, setF_Name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [other_option, setOther_Option] = useState("");

  const handleChange = (event) => {
    setOther_Option(event.target.value);
  };

  const handleSubmit = async (e) => {
    // console.log("hii");
    e.preventDefault();
    let result = await fetch("https://humhai.in/ev/enquiry/", {
      method: "POST",
      body: JSON.stringify({
        name: f_name,
        email: email,
        mobile: mobile,
        state: state,
        city: city,
        address: address,
        message: message,
        customer:other_option,
      }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
      },
    });

    result = await result.json();
    // console.log(result, "result");
    // console.log(result.status, "true");
    if (result.status === true) {
      // console.log("dfhj");
      alert("Successful");
      setF_Name("");
      setMobile("");
      setEmail("");
      setState("");
      setCity("");
      setAddress("");
      setMessage("");
      setOther_Option("");
    }
    if (result.status === 500) {
      alert("Fill all required fields");
    }
  };

  return (
    <div className="centered1">
      <section style={{ padding: "10px", width: "80%" }}>
        <div>
          <div>
            <div className="heading-wrapper text-center">
              <h2 className="large-heading text-uppercase">Book a Test Ride</h2>
            </div>
          </div>
          <div>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="full-name"
                    label="Your Full Name"
                    variant="outlined"
                    required
                    fullWidth
                    value={f_name}
                    onChange={(e) => setF_Name(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="email"
                    label="Email ID"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="state"
                    label="State"
                    variant="outlined"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="city"
                    label="City"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="Address"
                    label="Address"
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Choose
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={other_option}
                      label="Choose"
                      onChange={handleChange}
                    >
                      <MenuItem value="choose" disabled>Choose</MenuItem>
                      <MenuItem value="delarship">Delarship</MenuItem>
                      <MenuItem value="buyer">Buyer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    id="comments"
                    label="Remarks/Suggestions"
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12} className="text-center">
                  <Button variant="contained" fullWidth onClick={handleSubmit}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
