import React from 'react'
import StartUpStudioBanner from './Assets/Images/start-up-studio.png'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

function StartUpStudioHeader() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={StartUpStudioBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default StartUpStudioHeader