import React from 'react'

function TermsAndConditions() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p className='display-6'>Terms and Conditions</p>
                    <p align='justify'>Welcome to Amosta!</p>
                    <p align='justify'>These terms and conditions outline the rules and regulations for the use of Amosta Solutions Private Limited's Website, located at https://www.amosta.com.</p>
                    <p align='justify'>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Amosta if you do not agree to take all of the terms and conditions stated on this page.</p>
                    <p align='justify'><strong>Cookies:</strong></p>
                    <p align='justify'>The website uses cookies to help personalize your online experience. By accessing Amosta, you agreed to use the required cookies.</p>
                    <p align='justify'>A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>
                    <p align='justify'>We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.</p>
                    <p align='justify'><strong>License:</strong></p>
                    <p align='justify'>Unless otherwise stated, Amosta Solutions Private Limited and/or its licensors own the intellectual property rights for all material on Amosta. All intellectual property rights are reserved. You may access this from Amosta for your own personal use subjected to restrictions set in these terms and conditions.</p>
                    <p align='justify'>You must not:
                        <ul>
                            <li>Copy or republish material from Amosta</li>
                            <li>Sell, rent, or sub-license material from Amosta</li>
                            <li>Reproduce, duplicate or copy material from Amosta</li>
                            <li>Redistribute content from Amosta</li>
                        </ul>
                    </p>
                    <p>This Agreement shall begin on the date hereof.</p>
                    <p align='justify'>Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. Amosta Solutions Private Limited does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of Amosta Solutions Private Limited, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Amosta Solutions Private Limited shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                    <p align='justify'>Amosta Solutions Private Limited reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.</p>
                    <p align='justify'>You warrant and represent that:
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy.</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                        </ul>
                    </p>
                    <p align='justify'>You hereby grant Amosta Solutions Private Limited a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.</p>
                    <p align='justify'><strong>Hyperlinking to our Content:</strong></p>
                    <p align='justify'>
                        The following organizations may link to our Website without prior written approval:
                        <ul>
                            <li>Government agencies</li>
                            <li>Search engines</li>
                            <li>News organizations</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                        </ul>
                    </p>
                    <p align='justify'>These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default TermsAndConditions