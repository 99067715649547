import React from "react";
import Slider from "react-slick";
import "./Ev.css";
import img1 from "../../Components/Assets/Images/Ev/1.jpg";
import img2 from "../../Components/Assets/Images/Ev/2.jpg";
import img8 from "../../Components/Assets/Images/Ev/8.jpg";
import img4 from "../../Components/Assets/Images/Ev/4.jpg";
import img6 from "../../Components/Assets/Images/Ev/6.jpg";
import img7 from "../../Components/Assets/Images/Ev/7.jpg";
import img3 from "../Assets/Images/Ev/3.jpg";
import img9 from "../Assets/Images/Ev/9.jpg";
import img20 from "../Assets/Images/Ev/20.png";
import img21 from "../Assets/Images/Ev/21.jpg";

export const EvSlider = () => {
  const settings2 = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="Categoriessection padding">
        <div className="container">
          <div className="headding">
            <h2>Categories</h2>
              <div className="row mt-4">
                <Slider {...settings2}>
                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img1}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img2}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img3}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img4}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img6}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img7}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img8}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img9}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>60*KM</span></p>
                        <p>Max Speed : <span>45*KM</span></p>
                        <p>Motor : <span>1000W</span></p>
                        <p>Battery : <span>Led/Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>Front Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img20}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Battery : <span>Eastman Lihium Battery</span></p>
                        <p>Weight Capacity : <span>500Kg</span></p>
                        <p>Body Type : <span>Open</span></p>
                        <p>Ground Clearance : <span>200mm</span></p>
                        <p>Width : <span>1000mm</span></p>
                        <p>Charging Time : <span>8Hr</span></p>                     
                        <p>Charger Type : <span>Lithium Iron</span></p>
                        <div className="evsildernone">
                        <p>Motor Type : <span>Brushless DC Motor</span></p>
                        <p>Range : <span>90Km/Hr</span></p>
                        <p>Break Type : <span>Lever Operated</span></p>
                        <p>Warranty : <span>1 Year</span></p>
                        <p>Features : <span>Roof Type Steel,Taxi Light,Standard Carrier,FM with Double Speaker , Fire Extinguisher</span></p>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="Categoriemainbox">
                    <div className="Categoriesbox">
                      <a href="#">
                        <div className="Categorieiconbox">
                          <img src={img21}></img>
                        </div>
                      </a>
                      <div className="evslider">
                        <p>Max Distance : <span>250*KM</span></p>
                        <p>Max Speed : <span>120*KM</span></p>
                        <p>Motor : <span>4000W</span></p>
                        <p>Battery : <span>72V Lithium</span></p>
                        <p>Charging Time : <span>4-5Hrs*</span></p>
                        <p>Break System : <span>F&R Disc</span></p>
                        <p>Lead Capacity : <span>200Kgs</span></p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
