import React from 'react'
import SoftwareSolutionsHeaderBanner from './Assets/Images/software-solutions-header.png'
import {
    MDBCarousel,
    MDBCarouselItem,
  } from 'mdb-react-ui-kit';

function SoftwareSolutionHeader() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={SoftwareSolutionsHeaderBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default SoftwareSolutionHeader