import React from "react";
import BackgroundCheckHeader from "./BackgroundCheckHeader";
// import WhyVerification from './Assets/Images/why-verification.png'
import EmploymentCard from "./Assets/Images/employment-final-2.png";
import SecurityCard from "./Assets/Images/security-final.png";
import SafetyCard from "./Assets/Images/safety-final.png";
import LegalCard from "./Assets/Images/legal-final.png";
import BackgroundVerificationCard from "./Assets/Images/background-verification-card.png";
import ResidenceVerificationCard from "./Assets/Images/residence-verification-card.png";
import EmploymentVerificationCard from "./Assets/Images/employment-verification-card.png";
import EducationVerificationCard from "./Assets/Images/education-verification-card.png";
import PoliceVerificationCard from "./Assets/Images/police-verification-card.png";
import CriminalCheckCard from "./Assets/Images/criminal-check-card.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, Grid } from "@mui/material";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
// import { maxWidth } from '@mui/system'

function BackgroundCheck() {
  const handleClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScLS5NYUAXVnbm_NhZqlzIl0eLsi-BW5jQ4m4EgOCnwtRodlw/viewform",
      "_blank"
    );
  };
  return (
    <div>
      <BackgroundCheckHeader />
      <div className="container-fluid">
        <div className="row my-3 mb-0 text-center">
          <div className="col-md-12">
            <p className="display-6">Why do we need Verification?</p>
          </div>
        </div>
        <div className="row mb-2">
          {/* <div className='col-md-6 text-center'>
            <img className='img-fluid' src={WhyVerification} alt='Why Amosta Verification'/>
          </div> */}
          <div className="col-md-12 mb-2">
            <p align="justify">
              As they say, human is a social animal. We love to socialize, meet
              new people on daily basis. Some of them become important part of
              our lives, in the form of co-workers, helping hands, employees,
              etc. Knowingly or unknowingly, directly or indirectly we share a
              fair bit of information with them, which may be misused by them.
              So, the question is, how to minimize the risk or avoid this
              situation. Well, the answer is to get your workforce verified
              before sharing any information with them.
            </p>
            <p align="justify">
              Background checks are an important tool used by employers,
              landlords, government agencies, and other organizations to verify
              information provided by individuals, and to ensure that they are
              trustworthy and suitable for a particular role or responsibility.
              Here are some reasons why background checks are necessary:
            </p>
          </div>
          <div className="col-md-6 mb-2">
            <MDBCard style={{ maxWidth: "100%" }} className="employment-card">
              <MDBRow className="g-0">
                <MDBCol md="6">
                  <MDBCardImage src={EmploymentCard} alt="..." fluid />
                </MDBCol>
                <MDBCol md="6">
                  <MDBCardBody>
                    <MDBCardTitle>Employment</MDBCardTitle>
                    <MDBCardText>
                      <p align="justify">
                        Employers often conduct background checks as part of
                        their hiring process to verify the accuracy of the
                        information provided by job applicants, including their
                        employment history, educational background, and criminal
                        records.
                      </p>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
          <div className="col-md-6 mb-2">
            <MDBCard style={{ maxWidth: "100%" }} className="security-card">
              <MDBRow className="g-0">
                <MDBCol md="6">
                  <MDBCardImage src={SecurityCard} alt="..." fluid />
                </MDBCol>
                <MDBCol md="6">
                  <MDBCardBody>
                    <MDBCardTitle>Security</MDBCardTitle>
                    <MDBCardText>
                      <p align="justify">
                        {" "}
                        Background checks are essential for maintaining
                        security, particularly in sensitive industries like
                        finance, law enforcement, and national security.
                        Employers need to know that their employees are
                        trustworthy and have a clean record to minimize the risk
                        of theft, fraud, or other criminal activities.
                      </p>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
          <div className="col-md-6">
            <MDBCard style={{ maxWidth: "100%" }} className="safety-card">
              <MDBRow className="g-0">
                <MDBCol md="6">
                  <MDBCardImage src={SafetyCard} alt="..." fluid />
                </MDBCol>
                <MDBCol md="6">
                  <MDBCardBody>
                    <MDBCardTitle>Safety</MDBCardTitle>
                    <MDBCardText>
                      <p align="justify">
                        Background checks are critical for ensuring the safety
                        of individuals, particularly in fields that involve
                        working with vulnerable populations like children, the
                        elderly, or people with disabilities. Background checks
                        can help to identify individuals who have a history of
                        violent or abusive behavior.
                      </p>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
          <div className="col-md-6">
            <MDBCard style={{ maxWidth: "100%" }} className="legal-card">
              <MDBRow className="g-0">
                <MDBCol md="6">
                  <MDBCardImage src={LegalCard} alt="..." fluid />
                </MDBCol>
                <MDBCol md="6">
                  <MDBCardBody>
                    <MDBCardTitle>Legal Compliance</MDBCardTitle>
                    <MDBCardText>
                      <p align="justify">
                        Some industries and professions are required by law to
                        conduct background checks as a part of their regulatory
                        requirements. For example, healthcare providers,
                        financial institutions, and childcare facilities are
                        required to conduct background checks to comply with
                        federal and state regulations.
                      </p>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </div>
        </div>
        <div className="row mb-3 my-3">
          <div className="col-12 text-center">
            <p className="display-6 text-center">What do we Offer?</p>
          </div>
        </div>
        <div className="row mb-3 my-3">
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={BackgroundVerificationCard}
                  alt="BGC Card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Background Verification
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={EmploymentVerificationCard}
                  alt="Employment Verification Card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Employment Verification
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={EducationVerificationCard}
                  alt="Education Verification Card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Education Verification
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={PoliceVerificationCard}
                  alt="Police Record Verification"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Police Record Verification
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={ResidenceVerificationCard}
                  alt="Residence Check Card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Residence Verification
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
          <div className="col-md-2 text-center my-3">
            <Card sx={{ maxWidth: "100%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  // height="50%"
                  image={CriminalCheckCard}
                  alt="Criminal Record Check"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Criminal Record Check
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions> */}
            </Card>
          </div>
        </div>
      </div>
      <hr/>
      <div>
        <Grid
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>Open Link Google Form</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button fullWidth variant="contained" onClick={handleClick}>
              Get Code
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default BackgroundCheck;
