import React from 'react'

function ShippingPolicy() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        Shipping Policy
                    </p>
                    <p align='justify'>
                        We currently offer FREE DELIVERY on all online paid orders. You need to pay shipping charges of Rs. 300 on COD orders.
                    </p>
                    <p align='justify'>
                        The large majority of our orders are shipped within 5-7 working days of the order, and delivered in 2-3 days from shipping. We ship through leading courier services, and you can track your order online after it is shipped.
                    </p>
                </div>
                <div className='col-12 text-center'>
                    <p className='display-6'>
                        Warranty Policy
                    </p>
                    <p align='justify'>
                        This PocketSurfer (Product) is free from defects in material and workmanship that result in Product failure during normal usage, according to the following terms and Conditions:<br/>
                        <ol>
                            <li>The limited warranty for the Product & Accessories extends as per given table below from beginning on the date of the purchase of the Product.<br/>
                                <table border='2'>
                                    <tr>
                                        <td className='text-center'><strong>Tablet & Handset</strong></td>
                                        <td className='text-center'><strong>Battery / Charger / Other Accessories</strong></td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>12 months</td>
                                        <td className='text-center'>6 months</td>
                                    </tr>
                                </table>
                            </li>
                            <li>The limited warranty extends to the original purchaser of the Product (Customer) and is not assignable or transferable to any subsequent purchaser/end user.</li>
                            <li>The limited warranty extends only to Customers who purchase the Product in the India.</li>
                            <li>During the limited warranty period, Amosta Solutions Pvt. Ltd. will repair, or replace, at Amosta option, any defective parts, or any parts that will not properly operate for their intended use with new or factory rebuilt replacement items if such repair or replacement is needed because of product malfunction or failure during normal usage. No charge will be made to the Customer for any such parts. Amosta Solutions Pvt. Ltd. will also pay for the labor charges incurred by Amosta Solutions Pvt. Ltd. in repairing or replacing the defective parts. The limited warranty does not cover defects in appearance, cosmetic, decorative or structural items, including farming, and any non-operative parts. Amosta limit of liability under the limited warranty shall be the actual cash value of the Product at the time the Customer returns the product for repair, determined by the price paid by the Customer for the product less a reasonable amount for usage. Amosta Solutions Pvt. Ltd. shall not be liable for any other losses or damages. These remedies are the Customer exclusive remedies for breach of warranty.</li>
                            <li>Upon the request from Amosta Solutions Pvt. Ltd., the Customer must prove the date of the original purchase of the Product by a dated bill of sale or dated itemized receipt.</li>
                            <li>The Customer shall bear the cost of shipping the Product to Amosta Solutions Pvt. Ltd. Amosta Solutions Pvt. Ltd. shall bear the cost of shipping the Product back to the Customer after the Completion of the Services under this limited warranty.</li>
                            <li>The Customer shall have no coverage or benefits under this limited warranty if any of the following conditions are applicable:
                                <ul>
                                    <li>The product has been subject to abnormal use, abnormal conditions, improper storage, exposure to moisture or dampness, unauthorized modifications, unauthorized connections, unauthorized repair, misuse, neglect, abuse, accident, alteration, improper installation, or other acts which are not the fault of Amosta Solutions Pvt. Ltd., including damage caused by shipping.</li>
                                    <li>The product has been damaged from external causes such as collision with an object, or from fire, flooding, sand, dirt, windstorm, lightning, earthquake, or damage from exposure to weather conditions, an Act of God, or battery leakage, theft, blown fuse, or improper use of any electrical source, or damage caused by the connection to other products not recommended for interconnection by Amosta Solutions Pvt. Ltd.</li>
                                    <li>Amosta Solutions Pvt. Ltd. was not advised by the Customer in writing to the alleged defect or malfunction of the Product within fourteen (14) days after the expiration of the applicable limited warranty period.</li>
                                    <li>The Product serial number plate or the accessory data code has been removed, defected or altered.</li>
                                    <li>The defect or damage was caused by the defective function of the cellular system or by inadequate signal reception by the external antenna.</li>
                                </ul>
                            </li>
                            <li>If a problem develops during the limited warranty period, the Customer shall take the following step-by-step procedure:
                                <ul>
                                    <li>The Customer should call Amosta Customer Service on +91 8750060033 and explain the problem experienced. If it is not possible to solve the problem over the phone and a defect is confirmed, Amosta Customer Services will issue Customer with a RA (Returns Authorization) number.</li>
                                    <li>Customer Service will advise the address, to which the device should be returned, prepaid and insured.Installation are not covered under this limited warranty.</li>
                                    <li>The Customer shall include the RA Number, return address, daytime phone and/ or fax number, complete description of the problem and proof of purchase.</li>
                                    <li>The Customer will be billed for any parts or labour charges not covered by this limited warranty. The Customer will be responsible for any expenses related to reinstallation of the product.</li>
                                    <li>Amosta Solutions Pvt. Ltd. will repair or authorize the repair of the product under the limited warranty within 30 days after receipt of the Product by Amosta Solutions Pvt. Ltd. or a Amosta Solutions Pvt. Ltd. authorized service center. If Amosta Solutions Pvt. Ltd. cannot perform repair covered under this limited warranty within 30 days, or after a reasonable number of attempts to repair the same defect, Amosta Solutions Pvt. Ltd. as its option; will provide a replacement Product or next higher model of the Product or refund the purchase price of the Product less a reasonable amount for usage.</li>
                                    <li>If the Product is returned to Amosta Solutions Pvt. Ltd. during the limited warranty period, but the problem with the Product is not covered under the terms and conditions of this limited warranty, the Customer will be notified and given an estimate of the charges the Customer must pay to have the Product repaired, with al shipping charges billed to the Customer. If the estimate is refused, the Product will be returned freight collect. if the Product is returned to Amosta Solutions Pvt. Ltd. after the expiration of the limited warranty period, Amosta normal service policies shall apply and the Customer will be responsible for all shipping charges.</li>
                                </ul>
                            </li>
                            <li>The product consists of newly assembled equipment that may contain used components that have been reprocessed to allow machine compliance with Product performance and reliability specifications.</li>
                            <li>ANY IMPLIED WARRANTY OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OR USE, SHALL BE LIMITED TO THE DURATION OF THE FOREGOING LIMITED WRITTEN WARRANTY. OTHERWISE, THE FOREGOING LIMITED WARRANTY IS THE CUSTOMER SOLE AND EXCLUSIVE REMEDY AND SHALL NOT BE LIABLE FOR SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF ANTICIPATED BENEFITS OR PROFITS, LOSS OF SAVINGS OR REVENUE, PUNTIVE DAMAGES, LOSS OF USE OF THE PRODUCT OR ANY ASSOCIATED EQUIPMENT, COST OF CAPITAL, COST OF ANY SUBSTITUTE EQUIPMENT OR FACILITIES, DOWNTIME, THE CLAIMS OF ANY THIRD PARTIES, INCLUDING CUSTOMERS, AND INJURY TO PROPERTY, RESULTING FROM THE PURCHASE OR USE OF THE PRODUCT OR A RISING. FROM BREACH OF THE WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, STRICT TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EVEN IF AMOSTA LTD KNEW OF THE LIKELIHOOD OF SUCH DAMAGES. AMOSTA LTD SHALL NOT BE LIABLE FOR DELAY IN RENDERING SERVICE UNDER THE LIMITED WARRANTY, OR LOSS OF USE DURING THE PERIOD THAT THE PRODUCT IS BEING REPAIRED.</li>
                            <li>Amosta Solutions Pvt. Ltd. neither assumes nor authorizes any authorized service center or any other person or entity to assume for it any other obligation or liability beyond that which is expressly provided for in this limited warranty including the provider or seller of any extended warranty or service agreement.</li>
                            <li>This is the entire warranty between Amosta Solutions Pvt. Ltd. and the Customer, and supersedes all prior and contemporaneous agreements or understandings, oral or written, and all communications relating to the product, and no representation, promise or condition not contained herein shall modify these terms.</li>
                            <li>This limited warranty allocates the risk of failure of the Product between the Customers and Amosta Solutions Pvt. Ltd. The allocation is recognized by the Customer and is reflected in the purchase price of the Product.</li>
                            <li>Any action, Complaint, Consumer complaint & Lawsuit during the pendency of warranty period or immediately after the lapse of warranty period must be commenced within Fifteen (15) months following delivery of the Product subject to the Jurisdiction of Courts at Amritsar District only.</li>
                        </ol>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default ShippingPolicy