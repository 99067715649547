import React from 'react'

function PrivacyPolicy() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center'>
                   <p className='display-6'>Privacy Policy</p>
                   <p align='justify'>
                        We are committed to improving your security, protecting your privacy. We will only use the information that we collect about you lawfully. We collect information about you for two reasons: firstly, to process your order and second, to provide you with the best possible service.
                   </p>
                   <p align='justify'>
                        We will not e-mail you in the future unless there is any matter related to you for your earlier relationship with Amosta Solutions Pvt. Ltd or you have given us your consent. The type of information we will collect about you is available in the booking forms.
                   </p>
                   <p align='justify'>
                        We will never collect sensitive information about you without your explicit consent. The information we hold will be accurate and up to date. You can check the information that we hold about you by emailing us. If you find any inaccuracies we will delete or correct it promptly.
                   </p>
                   <p align='justify'>
                        The personal information which we hold will be held securely in accordance with our internal security policy. We may use technology to track the patterns of behavior of visitors to our site and/or location. This can include using a "cookie" which would be stored on your browser. You can usually modify your browser to prevent this happening. The information collected in this way can be used to identify you unless you modify your browser settings.
                   </p>
                   <p align='justify'>
                        If you have any questions/comments about privacy, you may contact us in that regard.
                   </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default PrivacyPolicy