import React from 'react'
import StartUpStudioHeader from './StartUpStudioHeader'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ExpertConsultation from './Assets/Images/expert-consultation.png'
import DocumentConsultation from './Assets/Images/document-consultation.png'
import TechSupport from './Assets/Images/tech-support.png'
import DesignSupport from './Assets/Images/design-support.png'
import HiringSupport from './Assets/Images/hiring-support.png'
import VerificationSupport from './Assets/Images/verification-support.png'



function StartUpStudioOfferings() {
  return (
    <div>
      <StartUpStudioHeader/>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-3'>
            <h1 className='display-6'>
              What Do We <span className='title-background'> Offer?</span>
            </h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
              sx={{color: '#ffffff'}}
              color="white"
                title="One-on-One consultation with the experts"
                className='text-center title-background'
                
              />
              <CardMedia
                component="img"
                height="100%"
                image={ExpertConsultation}
                alt="Expert Consultation"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>Support in making business strategies</li>
                    <li>In-depth market research for making a business model</li>
                    <li>Mentorship</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 ,color:"#ffffff"}}>
              <CardHeader
                title="Compliance/Documentation Support"
                className='text-center title-background'
              />
              <CardMedia
                component="img"
                height="100%"
                image={DocumentConsultation}
                alt="Document Consultation"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>Company Registration</li>
                    <li>GST Registration & Filing</li>
                    <li>ESIC/PF and similar compliance</li>
                    <li>Other miscellaneous formality</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Technical Support & Assistance"
                className='text-center title-background'
              />
              <CardMedia
                component="img"
                height="100%"
                image={TechSupport}
                alt="Tech Support"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>Domain & Hosting support</li>
                    <li>Website & Web App Development</li>
                    <li>Web/App based product development</li>
                    <li>Technical Consultation</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Graphic Design Consultation"
                className='text-center title-background'
              />
              <CardMedia
                component="img"
                height="100%"
                image={DesignSupport}
                alt="Design Support"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>UI/UX Design</li>
                    <li>Web/App based product design</li>
                    <li>Marketing Collaterals – Visiting Cards, Flyers, Brochures, Catalogs, Logo, etc.</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Recruitment & Staffing Support"
                className='text-center title-background'
              />
              <CardMedia
                component="img"
                height="100%"
                image={HiringSupport}
                alt="Hiring Support"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>Manpower Sourcing</li>
                    <li>Hiring in various domains</li>
                    <li>IT/Non-IT Recruitment</li>
                    <li>Field/Desk Job Recruitment</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
          <div className='col-sm-6 col-md-4 col-lg-3 my-3'>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Background Check Solutions"
                className='text-center title-background'
              />
              <CardMedia
                component="img"
                height="100%"
                image={VerificationSupport}
                alt="Verification Support"
              />
              <CardContent>
                <Typography variant="body2" color="text.primary">
                  <ul>
                    <li>Background Verification</li>
                    <li>Past Employment Verification</li>
                    <li>Police Verification</li>
                    <li>Education Verification</li>
                    <li>Residence Verification</li>
                    <li>Criminal Record Check</li>
                  </ul>
                </Typography>
              </CardContent>      
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartUpStudioOfferings