import React from 'react'
import AboutUsHeader from './AboutUsHeader'
import AmostaTimeline from './AmostaTimeline'
import LaunchBanner from './Assets/Images/market-launch.png'

function About() {
  return (
    <div>
      <AboutUsHeader/>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 text-center my-4'>
            <h1 className='display-6'>Who We Are</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center'>
            <img className='img-fluid web-image' src={LaunchBanner} alt='Who We Are'/>
          </div>
          <div className='col-md-6'>
            <p align="justify">Amosta is a technology company, committed to cater the requirements of the people to live a better & happy life in era of internet of things, futuristic smart cities, connected world & super intelligent ecosystem. Be it your business, your family, your organization, your field resources, your remote assets or your workforce, everything is now a dot on big matrix of connected world. We equip businesses & organizations with our well researched solutions to exceed their productivity, efficiency, ROI and business objectives. We are a team of self-motivated tech enthusiast who thrive on building web based applications based on cutting-edge technologies.</p>
            <p align="justify">“Started in 2014 as a “Make in India” Tablet & Smart Phone manufacturing company, we have come a long way since then nurturing and developing countless business ideas and shaping a few of them into a successful business. We share our expertise in fields like Software Development, Background Check, Project Management and much more. We have consulted and helped various organisations from different backgrounds that have helped them scaling their business to a great extent.</p>
            <p align="justify">We also have our in-house Start-up Studio where we help upcoming start-ups to build their company in terms of making business strategies, getting all their compliances done and empowering their business with technology in the form of website,  mobile apps and even building their product (app based – mobile or web).</p>
          </div>
        </div>
      </div>
      <div className='container-fluid my-4'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <h1 className='display-6'>Our Journey So far</h1>
          </div>
          <div className='col-md-12'>
            <AmostaTimeline/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About