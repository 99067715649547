import React, { useState } from "react";
import ContactUsHeader from "./ContactUsHeader";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from '@mui/material/styles';

function ContactUs() {
  const [formData, setFormData] = useState({
    full_name: "",
    mobile: "",
    email: "",
    subject: "",
    message: "",
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVerified, setVerified] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRecaptchaVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await fetch("https://humhai.in/home/Amosta-lead/", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
        },
      });

      const data = await result.json();

      if (data.status === 200) {
        alert("Successful");
        setFormData({
          full_name: "",
          mobile: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        setError("Fill all required fields");
      }
    } catch (error) {
      setError("Failed to fetch");
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const siteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

  return (
    <div>
      <ContactUsHeader />
      <div className="container-fluid">
        <div className="row my-4">
          <div className={isSmallScreen ? "col-12 mb-4" : "col-md-6"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.318928493867!2d77.06934701547728!3d28.500050796814598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d196c2ea9c4a7%3A0x88fb059582025d82!2sAmosta%20Solutions%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1679395612189!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Amosta Maps"
            ></iframe>
          </div>
          <div className="col-md-6">
            <h1 className="display-6 text-center">
              Have a query? Feel free to reach us !!
            </h1>
            <form onSubmit={handleSubmit}>
              <Box
                className="my-2"
                sx={{
                  width: '100%',
                  maxWidth: 700,
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  label="Full Name"
                  variant="outlined"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box
                className="my-2"
                sx={{
                  width: '100%',
                  maxWidth: 700,
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  type="tel"
                  inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
                />
              </Box>
              <Box
                className="my-2"
                sx={{
                  width: '100%',
                  maxWidth: 700,
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  label="Email ID"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Box>
              <Box
                className="my-2"
                sx={{
                  width: '100%',
                  maxWidth: 700,
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  label="Subject"
                  variant="outlined"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </Box>
              <TextField
                fullWidth
                sx={{ maxWidth: "100%", width: 700 }}
                label="Message"
                multiline
                required
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />

              <div style={{ marginTop: "10px" }}>
                <ReCAPTCHA sitekey={siteKey} onChange={handleRecaptchaVerify} />
                {isVerified ? (
                  <p style={{ color: "green" }}>
                    reCAPTCHA verification successful!
                  </p>
                ) : (
                  " "
                )}
              </div>

              {error && <p style={{ color: 'red' }}>{error}</p>}

              <Stack direction="row" spacing={2} className="my-2">
                <Button 
                  variant="contained" 
                  color="primary" 
                  type="submit"
                  disabled={loading || !isVerified}
                  endIcon={loading ? null : <SendIcon />}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </Button>
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
