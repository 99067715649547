// import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Components/About';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import OurTeam from './Components/OurTeam';
import BackgroundChecks from './Components/BackgroundCheck';
import SoftwareSolutions from './Components/SoftwareSolution'
import BackgroundCheckOfferings from './Components/BackgroundCheckOfferings'
import Careers from './Components/Careers'
import ContactUs from './Components/ContactUs'
import LifeAtAmosta from './Components/LifeAtAmosta'
import Portfolio from './Components/Portfolio'
import ProjectManagement from './Components/ProjectManagement'
import ProjectManagementProcess from './Components/ProjectManagementProcess'
import SoftwareSolutionProcess from './Components/SoftwareSolutionProcess'
import StartUpStudio from './Components/StartUpStudio'
import StartUpStudioOfferings from './Components/StartUpStudioOfferings'
import StartUpStudioProcess from './Components/StartUpStudioProcess'
import Testimonials from './Components/Testimonials'
import LEDLights from './Components/LEDLights'
import Footer from './Components/Footer';
import PrivacyPolicy from './Components/PrivacyPolicy';
import RefundPolicy from './Components/RefundPolicy';
import ShippingPolicy from './Components/ShippingPolicy';
import TermsAndConditions from './Components/TermsAndConditions';
import  {EVVehicles}  from './Components/Ev/EVVehicles';
function App() {
  return (
    <div className="App">
    <Navbar/>
      <BrowserRouter>
        <Routes>
          <Route index element ={<Home/>}/>
          <Route path='/' element={<Home/>}/>
          <Route path='about' element={<About/>}></Route>
          <Route path='ourteam' element={<OurTeam/>}></Route>
          <Route path='backgroundcheck' element={<BackgroundChecks/>}></Route>
          <Route path='softwaresolution' element={<SoftwareSolutions/>}></Route>
          <Route path='backgroundcheckofferings' element={<BackgroundCheckOfferings/>}></Route>
          <Route path='careers' element={<Careers/>}></Route>
          <Route path='contactus' element={<ContactUs/>}></Route>
          <Route path='lifeatamosta' element={<LifeAtAmosta/>}></Route>
          <Route path='portfolio' element={<Portfolio/>}></Route>
          <Route path='projectmanagement' element={<ProjectManagement/>}></Route>
          <Route path='projectmanagementprocess' element={<ProjectManagementProcess/>}></Route>
          <Route path='softwaresolutionprocess' element={<SoftwareSolutionProcess/>}></Route>
          <Route path='startupstudio' element={<StartUpStudio/>}></Route>
          <Route path='startupstudioofferings' element={<StartUpStudioOfferings/>}></Route>
          <Route path='startupstudioprocess' element={<StartUpStudioProcess/>}></Route>
          <Route path='testimonials' element={<Testimonials/>}></Route>
          <Route path='ledlights' element={<LEDLights/>}></Route>
          <Route path='privacypolicy' element={<PrivacyPolicy/>}></Route>
          <Route path='refundpolicy' element={<RefundPolicy/>}></Route>
          <Route path='shippingpolicy' element={<ShippingPolicy/>}></Route>
          <Route path='termsandconditions' element={<TermsAndConditions/>}></Route>
          <Route path='/ev'element={<EVVehicles/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;
