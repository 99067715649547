import React from 'react'
import ContactUsBanner from './Assets/Images/contact-us.png'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

function ContactUsHeader() {
  return (
    <MDBCarousel fade>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={ContactUsBanner}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default ContactUsHeader